<script lang="ts">
import { useAuthInterpreter, useAuthenticated } from '@nhost/vue'
import { useLocale } from "vuetify";

export default {
  setup() {
    const { t } = useLocale();
    const getTranslation = (path: string) => t(path);
    return {
      getTranslation
    }
  },
  created() {
    const refreshToken = this.$route.query.token as string;
    
    const service = useAuthInterpreter();


    const url = `${import.meta.env.VITE_API_GRAPQHQL_URL}/v1/auth/token`;
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ refreshToken })
    }).then(response => {
      response.json().then(session => {
        service.value.send({
          type: 'SESSION_UPDATE',
          data: {
            session
          },
        });

        this.$router.push('/app')
      })

    })
  },
  methods: {  }
}
</script>

<template>
  <div class="sign-in pa-10 d-flex">
    <div class="w-75">
      <div class="d-flex justify-center">
        <v-img eager style="max-width: 80%;" class="mb-10" src="@/assets/logo_cockpit_full_svg.svg" />
      </div>

    </div>
  </div>
</template>

<style lang="scss" scoped>
.sign-in {
  justify-content: center;
  align-items: center;
  text-align: center;
}

.first-access-text {
  color: rgb(var(--v-theme-primary));
  cursor: pointer;
}

.forgot-password-text {
  color: rgb(var(--v-theme-primary));
  cursor: pointer;
}
</style>
