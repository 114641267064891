<script lang="ts">
import { useUserDefaultRole } from '@nhost/vue';

export default {
  data() {
    return {}
  },
  created() {
        const { value: role } = useUserDefaultRole()
        if (role === 'investor') window.location.replace(`${import.meta.env.VITE_APP_CONTROLTOWER_COMPANIES_URL}`);
        else if (role === 'user') window.location.replace(`${import.meta.env.VITE_APP_COCKPIT_URL}`);
  }
}
</script>

<template>
  <main></main>
</template>
